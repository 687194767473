import {useCallback, useEffect, useLayoutEffect, useContext} from 'react';
import {useParams} from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import {SupportedLanguagesMessages} from "../LocaleContext";
import {FormattedMessage} from 'react-intl'

function Ordinary({id, name, patch, syncPatch, helperText, subscription}) {
    console.log(`Ordinary ${id} rendering`)
    //console.log(`DEBUG ${JSON.stringify(propData)}`)

    const { lang } = useParams();

    // default data
    // useLayoutEffect runs after all other useEffect calls, which is needed here, because otherwise 
    // in Part.js the useEffect hook that depends on propData 'overwrites' this useEffect hook
    useLayoutEffect(() => {
        console.log(`Ordinary ${id} effect depending on [id], default text for patch ${JSON.stringify(patch)}`)
        if ( !('text' in patch) ) {
            syncPatch({text: ORDINARY[lang][id]})
            console.log(`Ordinary ${id} apply default vernacular ${lang}`)
        }
    }, [id])

    // user editing text
    const handleText = useCallback((e) => {
        console.log(`Ordinary ${id} onchange handler`)
        syncPatch({text: e.target.value})
    }, [id])

    // user pressing button to set default text
    const missal_ver = useCallback(() => {
        console.log(`Ordinary ${id} button handler missal`)
        syncPatch({text: ORDINARY[lang][id]})
    }, [id])
    const missal_lat = useCallback(() => {
        console.log(`Ordinary ${id} button handler missal`)
        syncPatch({text: ORDINARY['lat'][id]})
    }, [id])
    const missal_verlat = useCallback(() => {
        console.log(`Ordinary ${id} button handler missal`)
        syncPatch({text: ORDINARY[lang][id] + "\n\n\n" + ORDINARY['lat'][id]})
    }, [id])

    // user presses button to clear data
    const clear = useCallback(() => {
        console.log(`Ordinary ${id} button handler clear`)
        syncPatch({text: ""})
    }, [id])

    return (
        <Stack spacing={4}>
            <Typography variant="h4" gutterBottom>{name}</Typography>
            <ButtonGroup variant="outlined" fullWidth={true}>
                <Button onClick={clear} startIcon={<DeleteIcon />}/>
                <Button onClick={missal_ver}>
                    <FormattedMessage {...SupportedLanguagesMessages[lang]}/>
                </Button>
                <Button onClick={missal_lat}>
                    <FormattedMessage id="ordinary.latin" defaultMessage="Latin"/>
                </Button>
                <Button onClick={missal_verlat}>
                    <FormattedMessage id="ordinary.latin" defaultMessage="Latin"/>
                    {' & '}
                    <FormattedMessage {...SupportedLanguagesMessages[lang]}/>
                </Button>
            </ButtonGroup>
            <TextField disabled={!subscription} value={patch.text} helperText={subscription ? helperText : ""} onChange={handleText} multiline maxRows={100} variant="filled" fullWidth/>
        </Stack>
    )
}

const ORDINARY = {
    lat: {
        Kyrie: `℣. Kyrie eleison. \n\n℟. Kyrie eleison. \n\n℣. Christe eleison. \n\n℟. Christe eleison. \n\n℣. Kyrie eleison. \n\n℟. Kyrie eleison.`,
        Gloria: `Gloria in excelsis Deo et in terra pax hominibus bonae voluntatis. Laudamus te, benedicimus te, adoramus te, glorifcamus te, gratias agimus tibi propter magnam gloriam tuam, Domine Deus, Rex caelestis, Deus Pater omnipotens. Domine Fili unigenite, Jesu Christe, Domine Deus, Agnus Dei, Filius Patris; \n\n\nqui tollis peccata mundi, miserere nobis; qui tollis peccata mundi, suscipe deprecationem nostram; qui sedes ad dexteram Patris, miserere nobis. Quoniam tu solus Sanctus, Tu solus Dominus, Tu solus Altissimus,  Jesu Christe, cum Sancto Spiritu in gloria Dei Patris. Amen. `,
        Offertorium: `℣. Orate, fratres, ut meum ac vestrum sacrificium acceptabile fit apud Deum Patrem omnipotentem. \n\n℟. Suscipiat Dominus sacrificium de manibus tuis ad laudem et gloriam nominis sui, ad utilitatem quoque nostram totius que Ecclesiae suae sanctae.`,
        SursumCorda: `℣. Dominus vobiscum \n\n℟. Et cum spiritu tuo. \n\n℣. Sursum Corda. \n\n℟. Habemus ad Dominum. \n\n℣. Gratias agamus Domino Deo nostro. \n\n℟. Dignum et iustum est. `,
        Sanctus: `Sanctus, Sanctus, Sanctus, Dominus Deus Sabaoth. \n\nPleni sunt caeli et terra in gloria tua. \n\nHosanna in excelsis. \n\nBenedictus qui venit in nomine Domini. \n\nHosanna in excelsis.`,
        Mysterium: `℣. Mysterium fidei. \n\n℟. Mortem tua annuntiamus, Domine, et tuam resurrectionem confitemur, donec venias.`,
        PaterNoster: `Pater noster, qui es in caelis: sanctificetur nomen tuum; adveniat regnum tuum; fiat voluntas tua, sicut in caelo, et in terra. Panem nostrum cotidianum da nobis hodie; et dimitte nobis debita nostra, sicut et nos dimittimus debitoribus nostris; et ne nos inducas in tentationem; sed libera nos a malo.`,
        LiberaNos: `℣. Libera nos, quaesumus, Domine, ab omnibus malis, da propitius pacem in diebus nostris, ut, ope misericordiae tuae adiuti, et a paccato simus semper liberi et ab omni perturbatione securi: exspectantes beatam spem et adventum Salvatoris nostri Iesu Christ. \n\n℟. Quia tuum est regnum, et potestas, et gloria in saecula.`,
        PaxDomini: `℣. Pax Domini sit semper vobiscum. \n\n℟. Et cum spiritu tuo. `,
        AgnusDei: `Agnus Dei, qui tollis peccata mundi; miserere nobis. \n\nAgnus Dei, qui tollis peccata mundi; miserere nobis. \n\nAgnus Dei, qui tollis peccata mundi; dona nobis pacem.`,
        NonSumDignus: `℣. Ecce Agnus Dei, ecce qui tollit peccata mundi. Beati qui ad cenam Agni vocati sunt. \n\n℟. Domine, non sum dignus, ut intres sub tectum meum, sed tantum dic verbo et sanabitur anima mea.`,
        RitusConclusionis: `℣. Dominus vobiscum. \n\n℟. Et cum spiritu tuo. \n\n℣. Benedicat vos omnipotens Deus, Pater, et Filius, et Spiritus Sanctus. \n\n℟. Amen. \n\n℣. Ite, missa est. \n\n℟. Deo gratias.`
    },
    de: {
        Kyrie: `℣. Herr, erbarme dich. \n\n℟. Herr, erbarme dich. \n\n℣. Christus, erbarme dich. \n\n℟. Christus, erbarme dich. \n\n℣. Herr, erbarme dich. \n\n℟. Herr, erbarme dich.`,
        Gloria: `Ehre sei Gott in der Höhe und Friede auf Erden den Menschen seiner Gnade.  Wir loben dich, wir preisen dich, wir beten dich an, wir rühmen dich.  Wir danken dir, denn groß ist deine Herrlichkeit: Herr und Gott, König des Himmels, Gott und Vater, Herrscher über das All Herr, eingeborener Sohn, Jesus Christus.  Herr und Gott, Lamm Gottes, Sohn des Vaters, \n\n\der du nimmst hinweg die Sünde der Welt: erbarme dich unser; der du nimmst hinweg die Sünde der Welt: nimm an unser Gebet; du sitzest zur Rechten des Vaters: erbarme dich unser.  Denn du allein bist der Heilige, du allein der Herr, du allein der Höchste, Jesus Christus, mit dem Heiligen Geist, zur Ehre Gottes des Vaters. Amen. `,
        Offertorium: `℣. Betet, Brüder und Schwestern, dass mein und euer Opfer Gott, dem allmächtigen Vater, gefalle. \n\n℟. Der Herr nehme das Opfer an aus deinen Händen, zum Lob und Ruhme seines Namens, zum Segen für uns und seine ganze heilige Kirche.`,
        SursumCorda: `℣. Der Herr sei mit euch. ℟. Und mit deinem Geiste. \n\n℣. Erhebet die Herzen. (Erhebet eure Herzen!) \n\n℟. Wir haben sie beim Herrn. \n\n℣. Lasset uns danken dem Herrn, unserm Gott. \n\n℟. Das ist würdig und recht.`,
        Sanctus: `Heilig, heilig, heilig Gott, Herr aller Mächte und Gewalten.  \n\nErfüllt sind Himmel und Erde von deiner Herrlichkeit.  \n\nHosanna in der Höhe.  \n\nHochgelobt sei, der da kommt im Namen des Herrn.  \n\nHosanna in der Höhe. `,
        Mysterium: `℣. Geheimnis des Glaubens \n\n℟. Deinen Tod, o Herr, verkünden wir, und deine Auferstehung preisen wir, bis du kommst in Herrlichkeit.`,
        PaterNoster: `Vater unser im Himmel, geheiligt werde dein Name.  Dein Reich komme.  Dein Wille geschehe wie im Himmel, so auf Erden.  Unser tägliches Brot gib uns heute und vergib uns unsere Schuld, wie auch wir vergeben unseren Schuldigern.  Und führe uns nicht in Versuchung, sondern erlöse uns von dem Bösen.`,
        LiberaNos: `℣. Erlöse uns, Herr, allmächtiger Vater, von allem Bösen und gib Frieden in unseren Tagen. Komm uns zu Hilfe mit deinem Erbarmen und bewahre uns vor Verwirrung und Sünde, damit wir voll Zuversicht das Kommen unseres Erlösers Jesus Christus erwarten. \n\n℟. Denn dein ist das Reich und die Kraft und die Herrlichkeit in Ewigkeit. Amen.`,
        PaxDomini: `℣. Der Friede des Herrn sei allezeit mit euch. \n\n℟. Und mit deinem Geiste.`,
        AgnusDei: `Lamm Gottes, du nimmst hinweg die Sünde der Welt, erbarme dich unser.  \n\nLamm Gottes, du nimmst hinweg die Sünde der Welt, erbarme dich unser.  \n\nLamm Gottes, du nimmst hinweg die Sünde der Welt, gib uns deinen Frieden. `,
        NonSumDignus: `℣. Seht das Lamm Gottes, das hinweg nimmt die Sünde der Welt. \n\n℟. Herr, ich bin nicht würdig, dass du eingehst unter mein Dach, aber sprich nur ein Wort, so wird meine Seele gesund.`,
        RitusConclusionis: `℣. Der Herr sei mit euch. \n\n℟. Und mit deinem Geiste. \n\n℣. Es segne euch der allmächtige Gott, der Vater und der Sohn ✠ und der Heilige Geist. \n\n℟. Amen. \n\n℣. Gehet hin in Frieden. \n\n℟. Dank sei Gott, dem Herrn.`
    },
    en: {
        Kyrie: `℣. Lord, have mercy. \n\n℟. Lord, have mercy. \n\n℣. Christ, have mercy. \n\n℟. Christ, have mercy. \n\n℣. Lord, have mercy. \n\n℟. Lord, have mercy.`,
        Gloria: `Glory to God in the highest, and peace to his people on earth. Lord God, heavenly King, almighty God and Father, we worship you, we give you thanks, we praise you for your glory. Lord Jesus Christ, only Son of the Father, Lord God, Lamb of God, \n\n\nyou take away the sin of the world, have mercy on us; you are seated at the right hand of the Father: receive our prayer. For you alone are the Holy One, you alone are the Lord,  you alone are the Most High, Jesus Christ, with the Holy Spirit, in the glory of God the Father. Amen.`,
        Offertorium: `℣. Pray, brethren, that our sacrifice may be acceptable to God, the almighty Father. \n\n℟. May the Lord accept the sacrifice at your hands for the praise and glory of his name, for our good, and the good of all his Church.`,
        SursumCorda: `℣. The Lord be with you. \n\n℟. And also with you. \n\n℣. Lift up your hearts. \n\n℟. We lift them up to the Lord. \n\n℣. Let us give thanks to the Lord our God. \n\n℟. It is right to give him thanks and praise.`,
        Sanctus: `Holy, holy, holy Lord. God of power and might. \n\nHeaven and earth are full of your glory. \n\nHosanna in the highest. \n\nBlessed is he who comes in the name of the Lord. \n\nHosanna in the highest.`,
        Mysterium: `℣. Let us proclaim the mystery of faith: \n\n℟. Christ has died, Christ is risen, Christ will come again.`,
        PaterNoster: `Our Father, who art in heaven, hallowed be thy name; thy kingdom come; thy will be done on earth as it is in heaven. Give us this day our daily bread; and forgive us our trespasses as we forgive those who trespass against us; and lead us not into temptation, but deliver us from evil.`,
        LiberaNos: `℣. Deliver us, Lord, from every evil, and grant us peace in our day. In your mercy keep us free from sin and protect us from all anxiety as we wait in joyful hope for the coming of our Saviour, Jesus Christ. \n\n℟. For the kingdom,  the power, and the glory are yours, now and forever.`,
        PaxDomini: `℣. The peace of the Lord be with you always. \n\n℟. And also with you.`,
        AgnusDei: `Lamb of God, you take away the sins of the world, have mercy on us. \n\nLamb of God, you take away the sins of the world, have mercy on us. \n\nLamb of God, you take away the sins of the world, grant us peace.`,
        NonSumDignus: `℣. This is the Lamb of God who takes away the sins of the world. Happy are those who are called to his supper. \n\n℟. Lord, I am not worthy to receive you, but only say the word and I shall be healed.`,
        RitusConclusionis: `℣. May the Lord be with you. \n\n℟. And with your spirit. \n\n℣. May almighty God bless you, the Father, and the Son, and the Holy Spirit. \n\n℟. Amen. \n\n℣. The Mass is ended, go in peace. \n\n℟. Thanks be to God. `
    },
    fr: {
        Kyrie: `℣. Seigneur, prends pitié. \n\n℟. Seigneur, prends pitié. \n\n℣. O Christ, prends pitié. \n\n℟. O Christ, prends pitié. \n\n℣. Seigneur, prends pitié. \n\n℟. Seigneur, prends pitié.`,
        Gloria: `Gloire à Dieu au plus haut des cieux et paix sur la terre aux hommes qu'il aime. Nous te louons, nous te bénissons, nous t'adorons, nous te glorifions, nous te rendons grâce, pour ton immense gloire, Seigneur Dieu, Roi du ciel, Dieu le Père tout-puissant, Seigneur Fils unique, Jésus-Christ, Seigneur Dieu, Agneau de Dieu, le Fils du Père, \n\n\ntoi qui enlèves les péchéis du monde, prends pitié de nous; toi qui enlèves les péchés du monde, reçois notre prière; toi qui es assis à la droite du Père, prends pitié de nous. Car toi seul es Saint, toi seul es Seigneur, toi seul es le Très-Haut: Jésus-Christ, avec le Saint-Esprit dans la gloire de Dieu le Père. Amen.`,
        Offertorium: `℣. Priez, frères et sœurs : que mon sacrifice, qui est aussi le vôtre, soit agréable à Dieu le Père tout-puissant. \n\n℟. Que le Seigneur reçoive de vos mains ce sacrifice à la louange et à la gloire de son nom, pour notre bien et celui de toute l’Eglise.`,
        SursumCorda: `℣. Le Seigneur soit avec vous. \n\n℟. Et avec votre esprit. \n\n℣. Élevons notre coeur. \n\n℟. Nous le tournons vers le Seigneur. \n\n℣. Rendons grâce au Seigneur notre Dieu. \n\n℟. Cela est juste et bon.`,
        Sanctus: `Saint, Saint, Saint, le Seigneur, Dieu de l'Univers. Le Ciel et la terre sont remplis de ta gloire. \n\nHosanna au plus haut des Cieux. \n\nBéni soit celui qui vient au nom du Seigneur. \n\nHosanna au plus haut des Cieux.`,
        Mysterium: `℣. Il est grand le mystère de la foi. \n\n℟. Nous annonçons ta mort, Seigneur Jésus, nous proclamons ta résurrection, nous attendons ta venue dans la gloire.`,
        PaterNoster: `Notre Père qui es au cieux, que ton nom soit sanctifié; que ton règne vienne; que ta volonté soit faite sur la terre comme au ciel.  Donne-nous aujourd'hui notre pain de ce jour; pardonne-nous nos offenses comme nous pardonnons aussi à ceux qui nous ont offensés; et ne nous laisse pas entrer en tentation; mais délivre-nous du mal.`,
        LiberaNos: `℣. Délivre-nous de tout mal, Seigneur, et donne la paix à notre temps; par la miséricorde, libère-nous du péché, rassure-nous devant les épreuves en cette vie où nous espérons le bonheur que tu promets et l'avènement de Jésus-Christ notre Sauveur. \n\n℟. Car c'est à toi qu'appartiennent le règne, la puissance et la gloire, pour les siècles des siècles. Amen`,
        PaxDomini: `℣. Que la paix du Seigneur soit toujours avec vous. \n\n℟. Et avec votre esprit.`,
        AgnusDei: `Agneau de Dieu, qui enlève les péchés du monde, prends pitié de nous. \n\nAgneau de Dieu, qui enlève les péchés du monde, prends pitié de nous. \n\nAgneau de Dieu, qui enlève le péché du monde, donne-nous la paix.`,
        NonSumDignus: `℣. Voici l'Agneau de Dieu, voici celui qui enlève les péchés du monde. Heureux les invités au repas des noces de l’Agneau ! \n\n℟. Seigneur, je ne suis pas digne de te recevoir, mais dis seulement une parole et je serai guéri.`,
        RitusConclusionis: `℣. Le Seigneur soit avec vous. \n\n℟. Et avec votre esprit. \n\n℣. Que Dieu tout-puissant vous bénisse, le Père, le Fils et le Saint-Esprit. \n\n℟. Amen. \n\n℣. Allez dans la paix du Christ. \n\n℟. Nous rendons grâce à Dieu.`
    },
    nl: {
        Kyrie: `℣. Heer, ontferm U. \n\n℟. Heer, ontferm U. \n\n℣. Christus, ontferm U. \n\n℟. Christus, ontferm U. \n\n℣. Heer, ontferm U. \n\n℟. Heer, ontferm U.`,
        Gloria: `Eer aan God in den hoge en vrede op aarde aan de mensen die Hij liefheeft. Wij loven U. Wij prijzen en aanbidden U. Wij verheerlijken U en zeggen U dank voor uw grote heerlijkheid. Heer God, hemelse Koning, God almachtige Vader; Heer, eniggeboren Zoon, Jezus Christus; Heer God, Lam Gods, Zoon van de Vader; \n\n\nGij die wegneemt de zonden der wereld, ontferm U over ons; Gij die wegneemt de zonden der wereld, aanvaard ons gebed; Gij die zit aan de rechterhand van de Vader, ontferm U over ons. Want Gij alleen zijt de Heilige. Gij alleen de Heer. Gij alleen de Allerhoogste: Jezus Christus, met de Heilige Geest in de heerlijkheid van God de Vader. Amen.`,
        Offertorium: `℣. Bid, broeders en zusters, dat mijn en uw offer aanvaard kan worden door God, de almachtige Vader. \n\n℟. Moge de Heer het offer uit uw handen aannemen, tot lof en eer van zijn Naam, tot welzijn van ons en van heel zijn heilige Kerk.`,
        SursumCorda: `℣. De Heer zij met u. \n\n℟. En met uw geest. \n\n℣. Verheft uw hart. \n\n℟. Wij zijn met ons hart bij de Heer. \n\n℣. Brengen wij dank aan de Heer, onze God. \n\n℟. Hij is onze dankbaarheid waardig. `,
        Sanctus: `Heilig, heilig, heilig de Heer, de God der hemelse machten. \n\nVol zijn hemel en aarde van uw heerlijkheid. \n\nHosanna in den hoge. \n\nGezegend Hij die komt in de Naam des Heren. \n\nHosanna in den hoge. `,
        Mysterium: `℣. Verkondigen wij het mysterie van het geloof: \n\n℟. Heer Jezus, wij verkondigen Uw dood, en wij belijden tot Gij wederkeert, dat Gij verrezen zijt.`,
        PaterNoster: `Onze Vader, die in de hemel zijt; uw naam worde geheiligd; uw rijk kome; uw wil geschiede op aarde zoals in de hemel. Geef ons heden ons dagelijks brood; en vergeef ons onze schulden, zoals ook wij vergeven aan onze schuldenaren; en breng ons niet in beproeving. Maar verlos ons van het kwade.`,
        LiberaNos: `℣. Verlos ons, Heer, van alle kwaad, geef vrede in onze dagen, dat wij, gesteund door uw barmhartigheid, vrij mogen zijn van zonde, en beveiligd tegen alle onrust. Hoopvol wachtend op de komst van Jezus, Messias, uw Zoon. \n\n℟. Want van U is het koninkrijk en de kracht en de heerlijkheid in eeuwigheid.  Amen.`,
        PaxDomini: `℣. De vrede van de Heer zij altijd met u. \n\n℟. En met uw geest.`,
        AgnusDei: `Lam Gods, dat de zonden van de wereld wegneemt: ontferm U over ons. \n\nLam Gods, dat de zonden van de wereld wegneemt: ontferm U over ons. \n\nLam Gods, dat de zonden van de wereld wegneemt: geef ons de vrede.`,
        NonSumDignus: `℣. Zie het Lam Gods, dat wegneemt de zonden van de wereld. Zalig zij die uitgenodigd zijn tot de Maaltijd des Heren. \n\n℟. Heer ik ben niet waardig dat Gij tot mij komt, Maar spreek en ik zal gezond worden.`,
        RitusConclusionis: `℣. De Heer zij met u. \n\n℟. En met uw geest. \n\n℣. Zegene u de almachtige God, Vader, Zoon en heilige Geest. \n\n℟. Amen. \n\n℣. Gaat nu allen heen in vrede. \n\n℟. Wij danken God.`
    }
}

export default Ordinary