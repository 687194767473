import {useCallback, useEffect, useLayoutEffect, useContext} from 'react';
import {useParams} from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import {FormattedMessage} from 'react-intl'

function Confiteor({id, name, patch, syncPatch, helperText, subscription}) {
    // note: id prop is read by Parent element "Part"; don't drop it!
    
    console.log(`Confiteor rendering`)
    //console.log(`DEBUG ${JSON.stringify(propData)}`)

    const { lang } = useParams();

    // default data
    // useLayoutEffect runs after all other useEffect calls, which is needed here, because otherwise 
    // in Part.js the useEffect hook that depends on propData 'overwrites' this useEffect hook
    useLayoutEffect(() => {
        console.log(`Confiteor effect default text for patch ${JSON.stringify(patch)}`)
        if ( !('text' in patch) ) {
            syncPatch({text: CONFITEOR[lang]['Confiteor']})
            console.log(`Confiteor apply default vernacular ${lang}`)
        }
    }, [])

    // user editing text
    const handleText = useCallback((e) => {
        console.log(`Confiteor onchange handler`)
        syncPatch({text: e.target.value})
    }, [])

    // user pressing button to set default text
    const confiteor_ver = useCallback(() => {
        console.log(`Confiteor button handler missal`)
        syncPatch({text: CONFITEOR[lang]['Confiteor']})
    }, [])
    const miserere_ver = useCallback(() => {
        console.log(`Confiteor button handler missal`)
        syncPatch({text: CONFITEOR[lang]['Miserere']})
    }, [])
    const confiteor_lat = useCallback(() => {
        console.log(`Confiteor button handler missal`)
        syncPatch({text: CONFITEOR['lat']['Confiteor']})
    }, [])
    const miserere_lat = useCallback(() => {
        console.log(`Confiteor button handler missal`)
        syncPatch({text: CONFITEOR['lat']['Miserere']})
    }, [])

    // user presses button to clear data
    const clear = useCallback(() => {
        console.log(`Confiteor button handler clear`)
        syncPatch({text: ""})
    }, [])

    return (
        <Stack spacing={4}>
            <Typography variant="h4" gutterBottom>{name}</Typography>
            <ButtonGroup variant="outlined" fullWidht={true}>
                <Button onClick={clear} startIcon={<DeleteIcon />}/>
                <Button onClick={confiteor_ver}>
                    <FormattedMessage id="confiteor.i_confess" defaultMessage="I confess..."/>
                </Button>
                <Button onClick={miserere_ver}>
                    <FormattedMessage id="confiteor.have_mercy" defaultMessage="Have mercy..."/>
                </Button>
                <Button onClick={confiteor_lat}>Confiteor...</Button>
                <Button onClick={miserere_lat}>Miserere...</Button>
            </ButtonGroup>
            <TextField disabled={!subscription} value={patch.text} helperText={subscription ? helperText : ""} onChange={handleText} multiline maxRows={100} variant="filled" fullWidth/>
        </Stack>
    )
}

const CONFITEOR = {
    lat: {
        Confiteor: `Confiteor Deo omnipotenti et vobis, fratres, quia peccavi nimis cogitatione, verbo, opere et omissione, mea cupla, mea culpa, mea maxima culpa. Ideo precor beatam Mariam semper Virginem, omnes Angelos et Sanctos, et vos, fratres, orare pro me ad Dominum Deum nostrum.`,
        Miserere: `℣. Miserére nostri, Dómine. \n\n℟. Quia peccávimus tibi. \n\n℣. Osténde nobis, Dómine, misericórdiam tuam. \n\n℟. Et salutáre tuum da nobis. \n\n℣. Misereátur nostri omnípotens Deus et, dimíssis peccátis nostris, perdúcat nos ad vitam ætérnam. \n\n℟. Amen`
    },
    de: {
        Confiteor: `Ich bekenne Gott, dem Allmächtigen, und allen Brüdern und Schwestern, dass ich Gutes unterlassen und Böses getan habe – ich habe gesündigt in Gedanken, Worten und Werken durch meine Schuld, durch meine Schuld, durch meine große Schuld.  Darum bitte ich die selige Jungfrau Maria, alle Engel und Heiligen und euch, Brüder und Schwestern, für mich zu beten bei Gott, unserm Herrn.`,
        Miserer: `℣. Erbarme dich, Herr, unser Gott, erbarme dich. \n\n℟.: Denn wir haben vor dir gesündigt. \n\n℣. Erweise, Herr, uns deine Huld. \n\n℟. Und schenke uns dein Heil. \n\n℣. Nachlass, Vergebung und Verzeihung unserer Sünden gewähre uns der allmächtige und barmherzige Herr. \n\n℟. Amen.`
    },
    en: {
        Confiteor: `I confess to almighty God, and to you, my brothers and sisters, that I have sinned through my own fault in my thoughts and in my words, in what I have done, and in what I have failed to do. And I ask blessed Mary, ever virgin, all the angels and saints, and you, my brothers and sisters, to pray for me to the Lord our God.`,
        Miserere: `℣. Have mercy on us, O Lord. \n\n℟. For we have sinned against you.  \n\n℣. Show us, O Lord, your mercy.  \n\n℟. And grant us your salvation.  \n\n℣. May almighty God have mercy on us, forgive us our sins, and bring us to everlasting life.  \n\n℟. Amen.`
    },
    fr: {
        Confiteor: `Je confesse à Dieu tout-puissant, je reconnais devant vous, frères et sœurs, que j’ai péché en pensée, en parole, par action et par omission ; oui, j’ai vraiment péché.  C’est pourquoi je supplie la bienheureuse Vierge Marie, les anges et tous les saints, et vous aussi, frères et soeurs, de prier pour moi le Seigneur notre Dieu.`,
        Miserere: `℣. Prends pitié de nous, Seigneur \n\n℟. Nous avons péché contre Toi\n\n℣. Montre-nous, Seigneur, ta miséricorde\n\n℟. Et donne-nous ton salut\n\n℣. Que Dieu Tout-Puissant nous fasse miséricorde; qu'il nous pardonne nos péchés et nous conduise à la vie éternelle. \n\n℟. Amen.`
    },
    nl: {
        Confiteor: `Ik belijd voor de almachtige God, en voor u allen, dat ik gezondigd heb in woord en gedachte, in doen en laten, door mijn schuld, door mijn schuld, door mijn grote schuld. Daarom smeek ik de heilige Maria, altijd maagd, alle engelen en heiligen, en u, broeders en zusters, voor mij te bidden tot de Heer, onze God.`,
        Miserere: `℣. Heer, ontferm U over ons. \n\n℟. Wij hebben gezondigd. \n\n℣. Toon ons, Heer, uw barmhartigheid. \n\n℟. En schenk ons uw heil. \n\n℣. Moge de almachtige God zich over ons ontfermen, onze zonden vergeven en ons geleiden tot het eeuwig leven. \n\n℟. Amen.`
    }
}

export default Confiteor