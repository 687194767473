import {useState, useCallback} from 'react'
import TextField from '@mui/material/TextField';
import validator from "validator";
import {useIntl} from 'react-intl'

function TextFieldValidEmail(props) {

    const intl = useIntl()
    const [message, setMessage] = useState("");

    const handleChange = useCallback((e) => {
        let value = e.target.value
        if (!value || validator.isEmail(value)) {
            setMessage('')
            props.onChange(e)
        } 
        else {
            setMessage(intl.formatMessage({id: 'textfieldvalidemail.email.error', defaultMessage: 'Please enter a valid email address'}));
        }
    }, [])

    return (
        <TextField {...props}
            onChange={handleChange}
            error={!!message}
            helperText={message}/>
    )
}

export default TextFieldValidEmail