import {useState, useCallback, useEffect, useContext} from 'react';
import {useParams} from "react-router-dom";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery } from '@tanstack/react-query'
import { api_proxy } from "../proxy";
import Typography from '@mui/material/Typography';
import {SupportedLanguagesProperAPIs} from "../LocaleContext";
import {FormattedMessage, useIntl} from 'react-intl'

function Proper({id, date, name, patch, syncPatch, helperText, subscription}) {
    console.log(`Proper ${id} rendering`)
    //console.log(`DEBUG ${JSON.stringify(propData)}`)

    const intl = useIntl()
    const [fetchingData, setFetchingData] = useState( !('text' in patch) )
    const { lang } = useParams();
    const [contentMessage, setContentMessage] = useState(null)  
    const [allowEdit, setAllowEdit] = useState(false)
    
    // call API for default data
    const {isLoading, fetchStatus, data} = useQuery({
        queryKey: [fetchingData, id, date], 
        queryFn: async () => {
            const api = SupportedLanguagesProperAPIs[lang]
            const res = await fetch(`https://${window.location.hostname}${api_proxy}/${api}?item=${id}&date=${date}`)
            return res.json()
        },
        enabled: fetchingData
    }, {
        refetchOnWindowFocus: false
    }); 
    const isLoadingV3 = isLoading && fetchStatus !== 'idle'

    // default data received from API
    useEffect(() => {
        console.log(`Proper ${id} effect depending on [data] = [${data}]`)
        if (data?.message) {
            console.log(`Proper ${id} set state based on fetched data`)
            setContentMessage(intl.formatMessage({
                id: "proper.content_message.success",
                defaultMessage: "Text retrieved from this <a>link</a>."
            },{
                a: chunks => <a target="_blank" href={data.url}>{chunks}</a>
            }))
            syncPatch({text: data.message})
            setFetchingData(false)
        }
        else if (data?.error) {
            setContentMessage(intl.formatMessage({
                id: "proper.content_message.error",
                defaultMessage: "Retrieving texts failed. Check this <a>link</a> for retrieving texts online."
            },{
                a: chunks => <a target="_blank" href={data.error.url}>{chunks}</a>
            }))
            setFetchingData(false)
            setAllowEdit(true)
        }
    }, [data, id])
 
    // user presses button to load data from content API
    const missaal = useCallback(() => {
        console.log(`Proper ${id} button handler missaal`)
        syncPatch({text: ""})
        setFetchingData(true)
    }, [id])
  
    // user editing text
    const handleText = useCallback((e) => {
        console.log(`Proper ${id} onchange handler`)
        syncPatch({text: e.target.value})
    }, [id])

    // user presses button to clear data
    const clear = useCallback(() => {
        console.log(`Ordinary ${id} button handler clear`)
        syncPatch({text: ""})
    }, [id])

    return (
        <Stack spacing={4}>
            <Typography variant="h4" gutterBottom>{name}</Typography>
            <ButtonGroup variant="outlined" fullWidth={true}>
                <Button onClick={clear} startIcon={<DeleteIcon />}/>
                <Button onClick={missaal}>
                    <FormattedMessage 
                        id="proper.missal" 
                        defaultMessage="missal"/>
                </Button>
            </ButtonGroup>
            { isLoadingV3 &&
                <CircularProgress/>
            }
            { !isLoadingV3 &&
                <TextField disabled={!subscription && !allowEdit} value={patch.text} helperText={subscription ? helperText : ""} onChange={handleText} multiline maxRows={100} variant="filled" fullWidth/>
            }
            { contentMessage &&
                <Typography variant="caption" gutterBottom>{contentMessage}</Typography>
            }
        </Stack>
    )
}

export default Proper