import {useState, useCallback, useEffect, useLayoutEffect} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import {FormattedMessage} from 'react-intl'

function Voorbedelied({id, name, patch, syncPatch, helperText}) {
    console.log(`Voorbedelied rendering`)
    //console.log(`DEBUG ${JSON.stringify(propData)}`)

    // default data
    useLayoutEffect(() => {
        console.log(`Voorbedelied effect depending on  [stateData], default text`)
        if ( !('text' in patch) ) {
            syncPatch({text: UBI})
        }
    }, [id])

    // user editing text
    const handleText = useCallback((e) => {
        console.log(`Voorbedelied onchange handler`)
        syncPatch({text: e.target.value})
    }, [])

    // user pressing button to set default text
    const ubi = useCallback(() => {
        console.log(`Voorbedelied button handler missal`)
        syncPatch({text: UBI})
    }, [])

    // user presses button to clear data
    const clear = useCallback(() => {
        console.log(`Voorbedelied button handler clear`)
        syncPatch({text: ""})
    }, [])

    return (
        <Stack spacing={4}>
            <Typography variant="h4" gutterBottom>{name}</Typography>
            <ButtonGroup variant="outlined">
                <Button onClick={clear} startIcon={<DeleteIcon />}/>
                <Button onClick={ubi}>Ubi caritas</Button>
            </ButtonGroup>
            <TextField value={patch.text} helperText={helperText} onChange={handleText} multiline maxRows={100} variant="filled" fullWidth/>
        </Stack>
    )
}

const UBI = `Ubi caritas et amor, Deus ibi est.`

export default Voorbedelied