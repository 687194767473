import {useParams} from 'react-router-dom'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Image from 'mui-image'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid';
import { react_proxy } from "./proxy";
import {FormattedMessage, useIntl} from 'react-intl'
import {SupportedLanguagesDemoURLs, SupportedLanguagesFacebookURLs, SupportedLanguagesDomains} from "./LocaleContext";
import TextFieldValidEmail from "./TextFieldValidEmail"
import {Helmet, HelmetProvider} from "react-helmet-async";

function Home() {

    const parish = uuidv4();
    const intl = useIntl()
    const { lang } = useParams();

    const submit = (event) => {
        event.preventDefault();
        if (event.target.email.value)
            window.location.href = `${react_proxy}/${lang}/edit/${parish}/${encodeURIComponent(event.target.parish_name.value)}/${encodeURIComponent(event.target.email.value)}/`
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{intl.formatMessage({id:"home.title", defaultMessage:"Slideshow Missale"})}</title>
                <meta property="og:title" content={intl.formatMessage({id: "home.title", defaultMessage:"Slideshow Missale"})} />
                <meta property="og:description" content={intl.formatMessage({id: "home.description", defaultMessage:"A catholic website to create slideshows that display answer prayers, lectures and hymns during Holy Mass"})} />
                <meta property="description" content={intl.formatMessage({id: "home.description", defaultMessage:"A catholic website to create slideshows that display answer prayers, lectures and hymns during Holy Mass"})} />
                <meta property="og:image" content={`${SupportedLanguagesDomains[lang]}${react_proxy}/slides-in-catholic-church.png`} />
            </Helmet>
            <Container maxWidth="md">
                <Stack spacing={4}>
                    <Typography variant="h2" gutterBottom>
                        <FormattedMessage id="home.title" defaultMessage="
                        Slideshow Missale"/>
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <FormattedMessage id="home.subtitle" defaultMessage="
                        Prayer texts for the faithful"/>
                    </Typography>
                    <Paper elevation={3}>
                        <Image src={`${react_proxy}/slides-in-catholic-church.png`} width="100%"/>
                    </Paper>
                    <Typography variant="body1" gutterBottom>
                        <FormattedMessage id="home.para1" defaultMessage="
                        Does your church have screens for displaying text during Mass? 
                        This site will help you making an online presentation containing the
                        prayers from the catholic missal that are spoken by the faithful, as well as the readings from
                        the Bible. Fixed prayers are included automatically, in the vernacular
                        or in Latin. Hymn texts can be added manually."/> 
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <FormattedMessage id="home.para2" values={{a: chunks => <a target="_blank" href={SupportedLanguagesDemoURLs[lang]}>{chunks}</a>}} defaultMessage="
                        Click the button to create a protected environment for a new parish
                        and start editing slides! Or have a look at a set of <a>demo slides for catholic Holy Mass</a>"/>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <FormattedMessage id="home.para3" values={{b: chunks => <b>{chunks}</b>}} defaultMessage="
                        <b>If you already have an account</b>, open the 
                        secret URL that you received when subscribing. It's a good idea to store
                        this in a bookmark on your PC!"/>
                    </Typography>
                    <Card raised sx={{mt: 10, p: 2}}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                <FormattedMessage id="home.subtitle-new-account" defaultMessage="
                                Create a free account"/>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <form onSubmit={submit}>
                                <Stack spacing={4}>
                                    <TextField
                                        name="parish_name" 
                                        variant="outlined" 
                                        required 
                                        label={intl.formatMessage({id: 'home.parish_name.label', defaultMessage: 'Name of your parish'})} 
                                        fullWidth/>
                                    <TextFieldValidEmail
                                        name="email" 
                                        variant="outlined" 
                                        required 
                                        label={intl.formatMessage({id: 'home.email.label', defaultMessage: 'Email address'})} 
                                        fullWidth/>
                                    <Button type="submit" variant="contained">
                                        <FormattedMessage id="home.button" defaultMessage="
                                        Get started!"/>
                                    </Button>
                                    <Alert severity="success" color="info">
                                        <Typography variant="body1" gutterBottom>
                                            <FormattedMessage id="home.para4" values={{a: chunks => <a href='mailto:info@missale.net'>info@missale.net</a>}} defaultMessage="
                                            The email address will only be used once, for sending the secret URL. The email address is not
                                            stored, so you will receive no further emails from us. That doesn't mean that
                                            we're out of touch. Please send your thoughts about this site to <a></a>!"/>
                                        </Typography>
                                    </Alert>
                                    <Typography variant="body1" gutterBottom>
                                        <FormattedMessage id="home.para5" defaultMessage="
                                        On the next screen, you get a secret URL that must be stored for later editing or displaying the
                                        prepared slides. Share the URL with other members
                                        of the liturgical team, e.g. someone responsible for selecting the hymns,
                                        who can add their contributions to the same presentation. You will also receive an email
                                        containing the secret URL for safekeeping."/>
                                    </Typography>
                                </Stack>
                            </form>
                        </CardActions>
                    </Card>
                    <Typography variant="body1">
                        (<i><b>e</b></i>) <a href="mailto:info@missale.net">info@missale.net</a> (<i><b>f</b></i>) <a href={SupportedLanguagesFacebookURLs[lang]}>{SupportedLanguagesFacebookURLs[lang].replace('https://','')}</a>
                    </Typography>
                </Stack>
            </Container>
        </HelmetProvider>
    )
}

export default Home