import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import {useIntl} from 'react-intl'

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const intl = useIntl()

  const [message, setMessage] = useState({severity: null, text: ""});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage({
            severity: "success", 
            text: intl.formatMessage({id:"checkoutform.message.payment_succeeded", defaultMessage:"Payment succeeded!"})
          });
          break;
        case "processing":
          setMessage({
            severity: "info", 
            text: intl.formatMessage({id:"checkoutform.message.payment_processing", defaultMessage:"Your payment is processing."})
          });
          break;
        case "requires_payment_method":
          setMessage({
            severity: "warning", 
            text: intl.formatMessage({id:"checkoutform.message.payment_unsuccessful", defaultMessage:"Your payment was not successful, please try again."})
          });
          break;
        default:
          setMessage({
            severity: "error", 
            text: intl.formatMessage({id:"checkoutform.message.payment_error", defaultMessage:"Something went wrong."})
          });
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.toString()
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage({severity: "error", text: error.message});
    } else {
      setMessage({
        severity: "error", 
        text: intl.formatMessage({id:"checkoutform.message.unexpected_error", defaultMessage:"An unexpected error occurred."})
      });
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <Button disabled={isLoading || !stripe || !elements} type="submit" variant="contained">
          {isLoading ? <div className="spinner" id="spinner"></div> : intl.formatMessage({id:"checkoutform.button.pay_now", defaultMessage:"Pay now"})}
        </Button>
        <Snackbar open={!!message?.text}>
          <Alert severity={message.severity}>{message.text}</Alert>
        </Snackbar>
      </Stack>
    </form>
  );
}