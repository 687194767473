// in debug_mode, code-server is providing reverse proxy for /proxy/<env.PORT> to 
// in deploy mode, nginx is providing reverse proxy for / to port 3020 (serves react and api) and for /proxy/ to port 3021 (serves websocket):
/*
        location / {
                proxy_pass http://localhost:3020/;
                proxy_set_header Upgrade $http_upgrade;
                proxy_set_header Connection upgrade;
                proxy_set_header Accept-Encoding gzip;
        }

        location /proxy/ {
                proxy_pass http://localhost:3021/;
                proxy_set_header Upgrade $http_upgrade;
                proxy_set_header Connection upgrade;
                proxy_set_header Accept-Encoding gzip;
                proxy_connect_timeout 7d;
                proxy_send_timeout 7d;
                proxy_read_timeout 7d;
        }
*/
// this is the setup for the client
// see also index.js for server side configuration
const debug_mode = window.location.hostname.includes("gelovenleren.net")  // this is my code-server development server
const react_port = 3022  // this value is also set in package.json
const http_port = debug_mode ? 3024 : 3020
const ws_port = debug_mode ? 3023 : 3021
const react_proxy = debug_mode ? `/proxy/${react_port}` : ""
const api_proxy = debug_mode ? `/proxy/${http_port}` : ""
const ws_proxy = debug_mode ? `/proxy/${ws_port}` : `/proxy/${ws_port}`

export {react_port, http_port, ws_port, react_proxy, api_proxy, ws_proxy, debug_mode}