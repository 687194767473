import {useCallback, useEffect, useLayoutEffect, useContext} from 'react';
import {useParams} from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import {FormattedMessage} from 'react-intl'

function Creed({id, name, patch, syncPatch, helperText, subscription}) {
    // note: id prop is read by Parent element "Part"; don't drop it!
    
    console.log(`Creed rendering`)
    //console.log(`DEBUG ${JSON.stringify(propData)}`)

    const { lang } = useParams();

    // default data
    // useLayoutEffect runs after all other useEffect calls, which is needed here, because otherwise 
    // in Part.js the useEffect hook that depends on propData 'overwrites' this useEffect hook
    useLayoutEffect(() => {
        console.log(`Creed effect default text for patch ${JSON.stringify(patch)}`)
        if ( !('text' in patch) ) {
            syncPatch({text: CREED[lang]['Credo']})
            console.log(`Creed apply default vernacular ${lang}`)
        }
    }, [])

    // user editing text
    const handleText = useCallback((e) => {
        console.log(`Creed onchange handler`)
        syncPatch({text: e.target.value})
    }, [])

    // user pressing button to set default text
    const missal_ver = useCallback(() => {
        console.log(`Creed button handler missal`)
        syncPatch({text: CREED[lang]['Credo']})
    }, [])
    const missal_verNC = useCallback(() => {
        console.log(`Creed button handler missal`)
        syncPatch({text: CREED[lang]['CredoNC']})
    }, [])
    const missal_lat = useCallback(() => {
        console.log(`Creed button handler missal`)
        syncPatch({text: CREED['lat']['Credo']})
    }, [])
    const missal_latNC = useCallback(() => {
        console.log(`Creed button handler missal`)
        syncPatch({text: CREED['lat']['CredoNC']})
    }, [])

    // user presses button to clear data
    const clear = useCallback(() => {
        console.log(`Creed button handler clear`)
        syncPatch({text: ""})
    }, [])

    return (
        <Stack spacing={4}>
            <Typography variant="h4" gutterBottom>{name}</Typography>
            <ButtonGroup variant="outlined" fullWidht={true}>
                <Button onClick={clear} startIcon={<DeleteIcon />}/>
                <Button onClick={missal_ver}>
                    <FormattedMessage id="creed.apostles_vernacular" defaultMessage="Apostles' creed"/>
                </Button>
                <Button onClick={missal_verNC}>
                    <FormattedMessage id="creed.nicene_vernacular" defaultMessage="Nicene creed"/>
                </Button>
                <Button onClick={missal_lat}>Symbolum apostolorum</Button>
                <Button onClick={missal_latNC}>Symbolum Nicaenum</Button>
            </ButtonGroup>
            <TextField disabled={!subscription} value={patch.text} helperText={subscription ? helperText : ""} onChange={handleText} multiline maxRows={100} variant="filled" fullWidth/>
        </Stack>
    )
}

const CREED = {
    lat: {
        Credo: `Credo in Deum Patrem omnipotentem, Creatorem caeli et terrae, et in Iesum Christum, Filium Eius unicum, Dominum nostrum, qui conceptus est de Spiritu Sancto, natus ex Maria Virgine, passus sub Pontio Pilato, crucifixus, mortuus, et sepultus, descendit ad inferos, \n\n\ntertia die resurrexit a mortuis, ascendit ad caelos, sedet ad dexteram Patris omnipotentis, inde venturus est iudicare vivos et mortuos. Credo in Spiritum Sanctum, sanctam Ecclesiam catholicam, sanctorum communionem, remissionem peccatorum, carnis resurrectionem, vitam aeternam. Amen.`,
        CredoNC: `Credo in unum Deum, Patrem omnipotentem, factorem caeli et terrae, visibilium omnium et invisibilium. Et in unum Dominum Jesum Christum, Filium Dei unigenitum, et ex Patre natum ante omnia saecula. Deum de Deo, lumen de lumine, Deum verum de Deo vero, genitum, non factum, consubstantialem Patri: per quem omnia facta sunt. Qui propter nos homines et propter nostram salutem descendit de caelis. ET INCARNATUS EST DE SPIRITU SANCTO EX MARIA VIRGINE, ET HOMO FACTUS EST. \n\n\nCrucifixus etiam pro nobis sub Pontio Pilato; passus et sepultus est, et resurrexit tertia die, secundum Scripturas, et ascendit in caelum, sedet ad dexteram Patris. Et iterum venturas est cum gloria, iudicare vivos et mortuos, cuius regni non erit finis. Et in Spiritum Sanctum, Dominum et vivificantem: qui ex Patre Filioque procedit. Qui cum Patre et Filio simul adoratur et conglorifcatur: qui locutus est per prophetas. Et unam, sanctam, catholicam, et apostolicum Ecclesiam. \n\n\nConfiteor unum baptismain remissionem peccatorum. Et exspecto resurrectionem mortuorum, et vitam venturi saeculi. Amen.`
    },
    de: {
        Credo: `Ich glaube an Gott, den Vater, den Allmächtigen, den Schöpfer des Himmels und der Erde.  Und an Jesus Christus, seinen eingeborenen Sohn, unsern Herrn, empfangen durch den Heiligen Geist, geboren von der Jungfrau Maria, gelitten unter Pontius Pilatus, gekreuzigt, gestorben und begraben, \n\n\nhinabgestiegen in das Reich des Todes, am dritten Tage auferstanden von den Toten, aufgefahren in den Himmel; er sitzt zur Rechten Gottes, des allmächtigen Vaters; von dort wird er kommen, zu richten die Lebenden und die Toten.  Ich glaube an den Heiligen Geist, die heilige katholische Kirche, Gemeinschaft der Heiligen, Vergebung der Sünden, Auferstehung der Toten und das ewige Leben. Amen.`,
        CredoNC: `Ich glaube an den einen Gott, den Vater, den Allmächtigen, der alles geschaffen hat, Himmel und Erde, die sichtbare und die unsichtbare Welt.  Und an den einen Herrn Jesus Christus, Gottes eingeborenen Sohn, aus dem Vater geboren vor aller Zeit: Gott von Gott, Licht von Licht, wahrer Gott vom wahren Gott, gezeugt, nicht geschaffen, eines Wesens mit dem Vater; durch ihn ist alles geschaffen.  Für uns Menschen und zu unserem Heil ist er vom Himmel gekommen, hat Fleisch angenommen durch den Heiligen Geist von der Jungfrau Maria und ist Mensch geworden.\n\n\nEr wurde für uns gekreuzigt unter Pontius Pilatus, hat gelitten und ist begraben worden, ist am dritten Tag auferstanden nach der Schrift und aufgefahren in den Himmel.  Er sitzt zur Rechten des Vaters und wird wiederkommen in Herrlichkeit, zu richten die Lebenden und die Toten; seiner Herrschaft wird kein Ende sein.  Und an den Heiligen Geist, der Herr ist und lebendig macht, der aus dem Vater und dem Sohn hervorgeht, der mit dem Vater und dem Sohn angebetet und verherrlicht wird, der gesprochen hat durch die Propheten.  Und an die eine, heilige, katholische und apostolische Kirche.\n\n\nIch bekenne eine Taufe zur Vergebung der Sünden.  Ich erwarte die Auferstehung der Toten und das Ende der kommenden Welt. Amen.`
    },
    en: {
        Credo: `I believe in God, the Father almighty, Creator of heaven and earth, and in Jesus Christ, his only Son, our Lord, who was conceived by the Holy Spirit, born of the Virgin Mary, suffered under Pontius Pilate, was crucified, died and was buried; he descended into hell;\n\n\non the third day he rose again from the dead; he ascended into heaven, and is seated at the right hand of God the Father almighty; from there he will come to judge the living and the dead. I believe in the Holy Spirit, the holy catholic Church, the communion of saints, the forgiveness of sins, the resurrection of the body, and life everlasting. Amen.`,
        CredoNC: `I believe in one God, the Father almighty, maker of heaven and earth, of all things visible and invisible. I believe in one Lord Jesus Christ, the Only Begotten Son of God, born of the Father before all ages. God from God, Light from Light, true God from true God, begotten, not made, consubstantial with the Father; through him all things were made.  For us men and for our salvation he came down from heaven, and by the Holy Spirit was incarnate of the Virgin Mary, and became man.\n\n\nFor our sake he was crucified under Pontius Pilate, he suffered death and was buried, and rose again on the third day in accordance with the Scriptures. He ascended into heaven and is seated at the right hand of the Father.  He will come again in glory to judge the living and the dead and his kingdom will have no end. I believe in the Holy Spirit, the Lord, the giver of life, who proceeds from the Father and the Son, who with the Father and the Son is adored and glorified, who has spoken through the prophets. I believe in one, holy, catholic and apostolic Church.\n\n\nI confess one Baptism for the forgiveness of sins and I look forward to the resurrection of the dead and the life of the world to come. Amen.`
    },
    fr: {
        Credo: `R. Je crois en Dieu, le Père tout-puissant, créateur du ciel et de la terre. Et en Jésus-Christ, son Fils unique, notre Seigneur, qui a été conçu du Saint-Esprit, est né de la Vierge Marie, a souffert sous Ponce Pilate, a été crucifié, est mort et a été enseveli, est descendu aux enfers, \n\n\nle troisième jour est ressuscité des morts, est monté aux cieux, est assis à la droite de Dieu le Père tout-puissant d'où il viendra juger les vivants et les morts. Je crois en l'Esprit Saint, à la sainte Eglise catholique, à la communion des saints, à la rémission des péchés, à la résurrection de la chair, à la vie éternelle. Amen.`,
        CredoNC: `R. Je crois en un seul Dieu, Le Père tout-puissant, Créateur du ciel et de la terre, de l'univers visible et invisible. Et en un seul Seigneur Jésus Christ, le Fils Unique de Dieu, Né du Père avant tous les siècles. Il est Dieu né de Dieu, Lumière née de la Lumière, vrai Dieu né du vrai Dieu,Engendré, non pas créé, consubstantiel au Père, par Qui tout a été créé. Pour nous les hommes et pour notre salut, Il descendit des cieux. IL A PRIS CHAIR DE LA VIERGE MARIE PAR ACTION DU SAINT-ESPRIT, ET IL S'EST FAIT HOMME. \n\n\nCrucifié pour nous sous Ponce Pilate, Il souffrit Sa Passion et fut mis au tombeau. Il ressucita le troisième jour, conformément aux Ecritures, Et Il monta au ciel, où Il siège à la droite du Père . Il reviendra dans la gloire pour juger les vivants et les morts ; Et Son règne n'aura pas de fin. Je crois en l'Esprit Saint, Qui est Seigneur et Qui donne la vie : Il procède du Père et du Fils . Avec le Père et le Fils, Il reçoit même adoration et même gloire : Il a parlé par les prophètes. Je crois à l'Eglise une, sainte, catholique et apostolique. \n\n\nJe reconnais un seul baptême pour la rémission des péchés. Et j'attends la résurrection des morts. Et la vie du monde à venir. Amen.`
    },
    nl: {
        Credo: `Ik geloof in God de almachtige Vader, Schepper van hemel en aarde. En in Jezus Christus, Zijn enige Zoon, onze Heer, Die ontvangen is van de Heilige Geest, geboren uit de maagd Maria, Die geleden heeft onder Pontius Pilatus, is gekruisigd, gestorven en begraven, Die nedergedaald is ter helle, \n\n\nde derde dag verrezen uit de doden, Die opgestegen is ten hemel, zit aan de rechterhand van God, de almachtige Vader, Vandaar zal Hij komen oordelen de levenden en de doden. Ik geloof in de Heilige Geest; De Heilige katholieke Kerk, de gemeenschap van de heiligen; De vergeving van de zonden; De verrijzenis van het lichaam; En het eeuwig leven. Amen.`,
        CredoNC: `Ik geloof in een God de almachtige Vader Schepper van hemel en aarde, van al wat zichtbaar en onzichtbaar is. En in een Heer, Jezus Christus, eniggeboren Zoon van God, voor alle tijden geboren uit de Vader. God uit God, licht uit licht, ware God uit de ware God. Geboren, niet geschapen, een in wezen met de Vader, en door wie alles geschapen is. Hij is voor ons, mensen, en omwille van ons heil uit de hemel neergedaald. HIJ HEEFT HET VLEES AANGENOMEN DOOR DE HEILIGE GEEST UIT DE MAAGD MARIA EN IS MENS GEWORDEN. \n\n\nHij werd voor ons gekruisigd, Hij heeft geleden onder Pontius Pilatus en is begraven Hij is verrezen op de derde dag, volgens de Schriften. Hij is opgevaren ten hemel: zit aan de rechterhand van de Vader. Hij zal wederkomen in heerlijkheid om te oordelen levenden en doden en aan zijn rijk komt geen einde. Ik geloof in de heilige Geest die Heer is en het leven geeft die voortkomt uit de Vader en de Zoon; die met de Vader en de Zoon tezamen wordt aanbeden en verheerlijkt; die gesproken heeft door de profeten. \n\n\nIk geloof in de ene, heilige, katholieke en apostolische kerk. Ik belijd een doopsel tot vergeving van de zonden. Ik verwacht de opstanding van de doden en het leven van het komend rijk. Amen.`
    }
}

export default Creed