/* https://frontend-digest.com/translate-create-react-app-with-react-intl-60c782bb7508 */

import {defineMessages} from 'react-intl'

// general purpose constant

export const SupportedLanguages = [
  {id: "en", name: "English", inname: "English"},
  {id: "nl", name: "Dutch", inname: "Nederlands"},
  {id: "de", name: "German", inname: "Deutsch"},
  {id: "fr", name: "French", inname: "Français"}
]

export const SupportedLanguagesMessages = defineMessages({
    en: {id: "localecontext.language.en", defaultMessage: "English"},
    nl: {id: "localecontext.language.nl", defaultMessage: "Dutch"},
    de: {id: "localecontext.language.de", defaultMessage: "German"},
    fr: {id: "localecontext.language.fr", defaultMessage: "French"}
})
   
export const SupportedLanguagesProperAPIs = {
  en: "usccb",
  nl: "tiltenberg",
  de: "kloster-einsiedeln",
  fr: "aelf"
}

export const SupportedLanguagesDemoURLs = {
  en: "https://slideshow.missale.net/slides?parish=b9d459a2-a98f-4bea-a051-ef0fdf05ba41&date=2023-05-28#1",
  nl: "https://dia.missale.net/slides?parish=488c0990-8539-4ca6-adff-df9338193703&date=2023-05-28#19",
  de: "https://diaschau.missale.net/slides?parish=59543a12-e01a-4fa2-bd72-cd8e2d6f74a9&date=2023-05-28#1",
  fr: "https://diaporama.missale.net/slides?parish=de9904a0-6223-4c02-98d7-3a8448a3e3cc&date=2023-05-28#1"
}

export const SupportedLanguagesFacebookURLs = {
  en: "https://facebook.com/slideshowmissale",
  nl: "https://facebook.com/diamissale",
  de: "https://facebook.com/diaschaumissale",
  fr: "https://facebook.com/diaporamamissale"
}

export const SupportedLanguagesDomains = {
  en: "https://slideshow.missale.net",
  nl: "https://dia.missale.net",
  de: "https://diaschau.missale.net",
  fr: "https://diaporama.missale.net"
}
