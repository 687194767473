import React, { useState, useEffect, useCallback } from 'react';

// Wraps part for syncing data with server
function Part({children, propData, send}) {
    const [patch, setPatch] = useState(propData)
    const [flag, setFlag] = useState(false)  // when 'true', patch must be synced to server

    const id = children.props.id

    // new data received via callback
    const syncPatch = useCallback((changed_fields) => {
        console.log(`Part ${id} synchPatch callback handler ${JSON.stringify(changed_fields)}`)
        setPatch((patch) => {
            let new_patch = {...patch,...changed_fields}  // new object, to trigger rerender!
            console.log(`Part ${id} setPatch to ${JSON.stringify(changed_fields)}`)
            return new_patch
        })
        setFlag(true)
    }, [])

    // new data received via prop
    useEffect(() => {
        console.log(`Part ${id} effect depending on [propData] = [${JSON.stringify(propData)}]`)
        setPatch((patch) => {
            patch = {...propData}  // new object, to trigger rerender!
            console.log(`Part ${id} setPatch to ${JSON.stringify(propData)}`)
            return patch
        })
        // no setFlag(true), to prevent data be sent back to the server (which would cause not only
        // superfluous db operations, but also infinite ping-pong between multiple clients)
    }, [propData, id])

    // syncing server
    useEffect(() => {
        console.log(`Part ${id} effect depending on [patch] = [${JSON.stringify(patch)}]`)
        if (flag) {
            send(id, patch)
            setFlag(false)
        }
    }, [patch, id, send])

    return (
        <>
            {React.cloneElement(children, {patch, syncPatch})}
        </>
    )
}

export default Part;