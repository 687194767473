import './App.css';
import { matchPath } from "react-router";
import Editor from './Editor'
import Home from './Home'
import { Route, Routes } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import { react_proxy } from "./proxy"

import en from "./lang/en";
import fr from "./lang/fr";
import de from "./lang/de";
import nl from "./lang/nl";

const messages = {
    en,
    fr,
    de,
    nl
};

function App() {
  const match = matchPath({ path: `${react_proxy}/:lang`, end: false }, window.location.pathname);
  const lang = match.params.lang

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
        <Routes>
          <Route path={`${react_proxy}/:lang`} element={<Home/>} />
          <Route path={`${react_proxy}/:lang/edit/:parish_id/`} element={<Editor/>} />
          <Route path={`${react_proxy}/:lang/edit/:parish_id/:parish_name/:email/`} element={<Editor/>} />
        </Routes>
      </LocalizationProvider>
    </IntlProvider>
  );
}

export default App;
