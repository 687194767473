import React, {useState, useEffect} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {api_proxy, debug_mode} from "./proxy";
import {useParams} from "react-router-dom";
import Typography from '@mui/material/Typography';

import CheckoutForm from "./CheckoutForm";
import "./App.css";
import {FormattedMessage} from "react-intl";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = debug_mode 
  ? loadStripe("pk_test_51MrJMtCPmkmZOx8VBymdPdoIue6yHq8i5hfvubWlLSRFV6yZyOopfGPMzQeqMsDI6EJVZQ8jCsYJu8ATTmz3qyOf00mFlY57IB")
  : loadStripe("pk_live_51MrJMtCPmkmZOx8VCxIk3TxFf3XnNnaOkTpjSwFSMYOns90mIai7RvO9MV6Xsh6OysSSk1o9lPiBS1VjLCeuUBXv00fpK4vJZX")

export default function Checkout({message}) {
  const [clientSecret, setClientSecret] = useState("");
  const [currency, setCurrency] = useState("")
  const [amount, setAmount] = useState("")
  // read parish id and (optionally) parish name from URL
  const {parish_id} = useParams();
  const {lang} = useParams();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(`https://${window.location.hostname}${api_proxy}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        items: [{ id: "prod_Nm4XAAt49Hucs2" }],
        metadata: {parish_id}
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret)
        setCurrency(data.currency)
        setAmount(data.amount)
      });
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
    locale: lang
  };

  return (
    <div className="App">
      {clientSecret && (
        <>
          <Typography gutterBottom>{message}</Typography>
          <Typography gutterBottom variant="button">
            <FormattedMessage id="checkout.amount" values={{currency, amount}} defaultMessage="Amount: {currency} {amount}"/>
          </Typography>
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </>
      )}
    </div>
  );
}